import React from "react"
import Layout from "../components/layout"
import "./work.css"
import WorkItem from "../components/WorkItem"

const projects = [
  {
    images: [
      require("../images/Splash.jpg"),
      require("../images/LoginDoctor.png"),
      require("../images/Profilemanagement.jpg"),
    ],
    title: "Vetzz",
    description: (
      <p>
        An Application to book Vet for home consultation. User can signup, book
        appointments and chat
        <br />
        <br />
        Technology Used: React Native, ExpressJS, GraphQL, PostgresSQL
      </p>
    ),
  },
  {
    images: [require("../images/jhm.jpeg")],
    title: "Join Hotel Management",
    description: (
      <p>
        An Application to help an Educational Consulatant. Students can see
        university and colleges listings.
        <br />
        <br />
        Technology Used: React, React Native, ExpressJS, PostgresSQL
      </p>
    ),
  },
  {
    images: [require("../images/bm.png")],
    title: "Business Simulator",
    description: (
      <p>
        Developed a Business Simulator Game for a Business School. Students can
        simulate business scenarios in form of a game
        <br />
        <br />
        Technology Used: React, NestJS, MongoDB
      </p>
    ),
  },
]

const WorkPage = () => {
  return (
    <Layout>
      <div className="work">
        <h1>My Projects</h1>
        <div className="projects">
          {projects.map(project => (
            <WorkItem {...project}></WorkItem>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default WorkPage

import React from "react"
import "./workitem.css"
import Carousel, { Modal, ModalGateway } from "react-images"

const WorkItem = ({ title, description, images }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  return (
    <div className="work-item">
      <h2>{title}</h2>
      <div className="images">
        <img
          onClick={() => {
            setModalIsOpen(true)
          }}
          src={images[0]}
        ></img>
      </div>
      {description}
      <div>
        <button
          onClick={() => {
            setModalIsOpen(true)
          }}
          className="primary"
        >
          View Screenshots
        </button>
      </div>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal
            onClose={() => {
              setModalIsOpen(false)
            }}
          >
            <Carousel views={images.map(image => ({ source: image }))} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default WorkItem
